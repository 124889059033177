<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Medicine</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-form action="apps-purchase" method="post" @submit="addMedicine">
			<b-card>
				<b-row>
					<b-col md="6">
						<b-form-group label="Purchase Date " label-for="Purchase-Date ">
							<b-form-datepicker
								name="purchase_date"
								v-model="FormData.purchase_date"
								menu-class="w-100"
								calendar-width="100%"
								class="mb-1"
							/>
						</b-form-group>
					</b-col>

					<b-col md="6">
						<b-form-group label="Shed No" label-for="Shed No">
							<div class="form-label-group">
								<b-form-input
									v-model="FormData.shed_no"
									placeholder="Shed No"
									type="text"
									name="shed_no"
								/>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label-for="Medicine Name" label="Medicine Name">
							<div class="form-label-group">
								<b-form-input
									v-model="FormData.medicine_name"
									placeholder="Medicine Name"
									type="text"
									name="medicine_name"
								/>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label-for="Source" label="Source">
							<div class="form-label-group">
								<b-form-input
									v-model="FormData.source"
									placeholder="Source"
									type="text"
									name="source"
								/>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label="Route " label-for="Route ">
							<div class="form-label-group">
								<b-form-select
									v-model="selectedRoute"
									:options="routeList"
									text-field="name"
									value-field="name"
									v-on:change="getSelectedRoute"
								/>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label="Unit Name" label-for="Unit Name ">
							<div class="form-label-group">
								<b-form-select
									v-model="selectedUnit"
									:options="unitList"
									text-field="name"
									value-field="name"
									v-on:change="getSelectedUnit"
								/>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label="Expiry date " label-for="Expiry date ">
							<b-form-datepicker
								v-model="FormData.expiry_date"
								name="expiry_date"
								menu-class="w-100"
								calendar-width="100%"
								class="mb-1"
							/>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label="Total Stock" label-for="Total Stock">
							<div class="form-label-group">
								<b-form-input
									v-model="FormData.total_stock"
									placeholder="Total Cost"
									type="text"
									name="total_stock"
								/>
							</div>
						</b-form-group>
					</b-col>

					<!-- submit and reset -->
					<b-col>
						<b-button
							type="submit"
							variant="primary"
							value="Submit"
							class="mr-1"
						>
							Submit
						</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-form>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BButton,
	BCardText,
	BForm,
	BFormGroup,
	BFormInput,
	BInputGroup,
	BInputGroupPrepend,
	BFormTextarea,
	BFormCheckbox,
	BPopover,
	VBToggle,
	BFormSelect,
	BFormDatepicker,
	BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BButton,
		BCardText,
		BForm,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
		BFormTextarea,
		BFormCheckbox,
		BPopover,
		BFormSelect,
		BFormDatepicker,
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			selected: null,
			FormData: {
				shed_no: null,
				medicine_name: null,
				source: null,
				total_stock: null,
				purchase_date: null,
				expiry_date: null,
			},
			selectedUnit: null,
			selectedRoute: null,
			unitList: null,
			routeList: null,
		};
	},
	created: function () {
		this.getUnit();
		this.getRoute();
	},
	methods: {
		getUnit() {
			axiosIns
				.get(`web/units`)
				.then((response) => {
					this.unitList = response.data.unitList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getRoute() {
			axiosIns
				.get(`web/routeType`)
				.then((response) => {
					this.routeList = response.data.routeList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getSelectedUnit(name) {
			this.selectedUnit = name;
		},
		getSelectedRoute(name) {
			this.selectedRoute = name;
		},
		addMedicine(e) {
			e.preventDefault();
			var data = new FormData();
			data.append("medicine_name", this.FormData.medicine_name);
			data.append("source", this.FormData.source);
			data.append("shed_no", this.FormData.shed_no);
			data.append("total_stock", this.FormData.total_stock);
			data.append("purchase_date", this.FormData.purchase_date);
			data.append("expiry_date", this.FormData.expiry_date);
			data.append("unit", this.selectedUnit);
			data.append("route", this.selectedRoute);
			data.append("company_id", this.$route.params.companyId);
			const hatchery_id = this.$route.params.hatcheryId;

			axiosIns
				.post(`web/hatchery/${hatchery_id}/purchaseMedicine`, data)
				.then((res) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: `data Successfully Added `,
						},
					});
					setTimeout(
						this.$router.push({
							path: `/apps/purchase`,
						}),
						3000
					);
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");

					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
				});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
	background-color: $product-details-bg;
}

.form-item-action-col {
	width: 27px;
}

.repeater-form {
	// overflow: hidden;
	transition: 0.35s height;
}

.v-select {
	&.item-selector-title,
	&.payment-selector {
		background-color: #fff;

		.dark-layout & {
			background-color: unset;
		}
	}
}

.dark-layout {
	.form-item-section {
		background-color: $theme-dark-body-bg;

		.row .border {
			background-color: $theme-dark-card-bg;
		}
	}
}
</style>
